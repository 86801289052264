import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators'
import store from './index'
import firebase, { auth } from '@/plugins/firebase'
import userCollection, { User } from '@/models/user'
import { Company, converter as companyConverter } from '@/models/company'
import { Group, converter as groupConverter } from '@/models/group'
import deviceCollection, { Device } from '@/models/device'
import dayCollection, { Day } from '@/models/day'
@Module({
  namespaced: true,
  store,
  name: 'auth',
  dynamic: true
})

class Auth extends VuexModule {
  user: User | null = null
  firebaseUser: firebase.User | null = null
  token: string | null = localStorage.getItem('token')
  companies: firebase.firestore.DocumentSnapshot<Company>[] = []
  groups: firebase.firestore.DocumentSnapshot<Group>[] = []
  loading = true
  selectedCompany: firebase.firestore.DocumentSnapshot<Company> | null = null
  selectedGroup: firebase.firestore.DocumentSnapshot<Group> | null = null

  get existsDisplayName (): boolean {
    if (!this.user) return false
    return !!this.user.displayName
  }

  get isAuthenticated (): boolean {
    if (!this.user) return false
    return true // this.user.actions.includes('read')
  }

  get isAdmin () {
    if (!this.user) return false
    if (!this.user.displayName) return false
    return this.user.actions.includes('admin')
  }

  get isDev () {
    if (!this.user) return false
    if (!this.user.displayName) return false
    return this.user.actions.includes('dev')
  }

  get isCompanyAdmin () {
    if (!this.user) return false
    return this.user.actions.includes('company-admin')
  }

  get isGroupAdmin () {
    if (!this.user) return false
    return this.user.actions.includes('group-admin')
  }

  get groupsInCompanies (): firebase.firestore.DocumentSnapshot<Group>[] {
    return this.selectedCompany
      ? this.groups.filter(doc => {
        const item = doc.data()
        return item?.company.id === this.selectedCompany?.id
      })
      : this.groups
  }

  get deviceRef (): firebase.firestore.Query<Device> { // not come
    console.log('heee')
    // return deviceCollection
    if (this.selectedGroup) {
      return deviceCollection.where('group', '==', this.selectedGroup.ref)
    } else {
      if (this.selectedCompany) {
        return this.isCompanyAdmin
          ? deviceCollection.where('company', '==', this.selectedCompany.ref)
          : deviceCollection.where('group', 'in', this.groupsInCompanies.map(g => g.ref))
      } else {
        return this.isAdmin
          ? deviceCollection
          : deviceCollection.where('company', 'in', this.companies.map(c => c.ref))
      }
    }
  }

  get dayRef (): firebase.firestore.Query<Day> {
    if (this.selectedGroup) {
      return dayCollection.where('group', '==', this.selectedGroup.ref)
    } else {
      if (this.selectedCompany) {
        return this.isGroupAdmin
          ? dayCollection.where('group', 'in', this.groupsInCompanies.map(g => g.ref))
          : dayCollection.where('company', '==', this.selectedCompany.ref)
      } else {
        return this.isCompanyAdmin
          ? dayCollection.where('company', 'in', this.companies.map(c => c.ref))
          : dayCollection
      }
    }
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }

  @Mutation
  setUser (user: User | null | undefined) {
    if (!user) this.user = null
    else this.user = user
  }

  @Mutation
  setFirebaseUser (firebaseUser: firebase.User | null) {
    this.firebaseUser = firebaseUser
  }

  @Mutation
  setCompanies (items: firebase.firestore.DocumentSnapshot<Company>[]) {
    this.companies = items
    if (!items.length) return
    this.selectedCompany = items[0]
  }

  @Mutation
  setGroups (items: firebase.firestore.DocumentSnapshot<Group>[]) {
    this.groups = items
  }

  @Mutation
  setCompany (company: firebase.firestore.DocumentSnapshot<Company> | null) {
    this.selectedGroup = null
    this.selectedCompany = company
  }

  @Mutation
  setGroup (group: firebase.firestore.DocumentSnapshot<Group> | null) {
    this.selectedGroup = group
  }

  @Action({ commit: 'setCompanies' })
  async getCompanies (user: User) {
    const companies: firebase.firestore.DocumentSnapshot<Company>[] = []
    for (const ref of user.companies) {
      const doc = await ref.withConverter(companyConverter).get()
      if (doc.exists) companies.push(doc)
    }
    return companies
  }

  @Action({ commit: 'setGroups' })
  async getGroups (user: User) {
    const groups: firebase.firestore.DocumentSnapshot<Group>[] = []
    for (const ref of user.groups) {
      const doc = await ref.withConverter(groupConverter).get()
      if (doc.exists) groups.push(doc)
    }
    return groups
  }

  @Action
  async updateDisplayName (displayName: string) {
    if (!this.firebaseUser) return
    const ref = userCollection.doc(this.firebaseUser.uid)
    await ref.set({ displayName }, { merge: true })
  }

  @Action
  async signOut () {
    // if (!this.firebaseUser) return
    // const ref = userCollection.doc(this.firebaseUser.uid)
    // await ref.set({ fcmToken: '' }, { merge: true })
    await auth.signOut()
  }
}
export default getModule(Auth)
