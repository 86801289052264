import { render, staticRenderFns } from "./PrivacyContent.vue?vue&type=template&id=0d5f1ee2&scoped=true&"
import script from "./PrivacyContent.vue?vue&type=script&lang=ts&"
export * from "./PrivacyContent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5f1ee2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
