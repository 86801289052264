import firebase, { auth } from '@/plugins/firebase'
import AuthStore from '@/store/AuthStore'
import userCollection, { User } from '@/models/user'
import companyCollection from '@/models/company'
import groupCollection from '@/models/group'
import FcmStore from '@/store/FcmStore'

class AuthHandler {
  unsubscribeUsers: firebase.Unsubscribe | null = null
  unsubscribeCompanies: firebase.Unsubscribe | null = null
  unsubscribeGroups: firebase.Unsubscribe | null = null
  unsubscribeFCM: firebase.Unsubscribe | null = null

  constructor () {
    this.checkingAuth()
  }

  private checkingAuth () {
    // AuthStore.signOut()
    auth.onAuthStateChanged((firebaseUser) => {
      console.log('onAuthStateChanged')
      setTimeout(() => {
        AuthStore.setLoading(false)
      }, 500)
      AuthStore.setFirebaseUser(firebaseUser)
      if (firebaseUser) {
        const ref = userCollection.doc(firebaseUser.uid)
        this.subscribe(ref)
      } else {
        this.unsubscribe()
      }
    })
  }

  private async subscribe (ref: firebase.firestore.DocumentReference<User>) {
    await this.subscribeFCM(ref).catch(e => console.error(`fcm err: ${e.message}`))
    this.unsubscribeUsers = ref.onSnapshot(doc => {
      if (!doc.exists) return
      const user = doc.data()
      AuthStore.setUser(user)
      if (AuthStore.isAdmin) {
        this.subscribeCompanies()
        this.subscribeGroups()
      } else {
        if (!user) return
        AuthStore.getCompanies(user)
        AuthStore.getGroups(user)
      }
      // AuthStore.getCompanies(user)
      // AuthStore.getGroups(user)
    }, err => console.error('user ' + err.message))
  }

  private unsubscribe () {
    AuthStore.setUser(null)
    if (this.unsubscribeFCM) {
      this.unsubscribeFCM()
      this.unsubscribeFCM = null
    }
    if (this.unsubscribeGroups) {
      this.unsubscribeGroups()
      this.unsubscribeGroups = null
    }
    if (this.unsubscribeCompanies) {
      this.unsubscribeCompanies()
      this.unsubscribeCompanies = null
    }
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers()
      this.unsubscribeUsers = null
    }
  }

  private subscribeCompanies () {
    this.unsubscribeCompanies = companyCollection.onSnapshot(sn => {
      AuthStore.setCompanies(sn.docs)
    })
  }

  private subscribeGroups () {
    this.unsubscribeGroups = groupCollection.onSnapshot(sn => {
      AuthStore.setGroups(sn.docs)
    })
  }

  private async subscribeFCM (ref: firebase.firestore.DocumentReference<User>) {
    // if (process.env.NODE_ENV === 'development') return

    try {
      if (!firebase.messaging.isSupported()) return
      const doc = await ref.get()
      if (!doc.exists) return
      const user = doc.data()
      if (!user) return
      const fcmToken = await firebase.messaging().getToken()
      if (!user.fcmTokens.includes(fcmToken)) {
        const fcmTokens = user.fcmTokens
        fcmTokens.push(fcmToken)
        await doc.ref.set({ fcmTokens }, { merge: true })
      }
      this.unsubscribeFCM = firebase.messaging().onMessage(message => {
        const notification = message.notification

        if (!notification) return
        FcmStore.addItem({
          createdAt: new Date(),
          title: notification.title,
          body: notification.body,
          link: notification.click_action
        })
      }, e => console.error('fcm subscribe err: ' + e.message))
    } catch (e) {
      console.error(`fcmOn err: ${e.message}`)
    }
  }

  // private fcmOff () {
  //   if (process.env.NODE_ENV === 'development') return
  //   if (typeof this.unsubscribeFCM === 'function') this.unsubscribeFCM()
  // }
}
export default new AuthHandler()
