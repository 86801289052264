


















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LayoutCenter extends Vue {
  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get pa (): string {
    return this.xs ? 'fill-height justify-center pa-0' : 'fill-height justify-center'
  }
}
