
































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import REGIONS from '@/assets/regions'

@Component<CountryCodeSelect>({})
export default class CountryCodeSelect extends Vue {
  @Prop({ type: Array, default: () => [] }) includes!: string[]
  @Prop({ type: String, default: '' }) inputClass!: string
  @Prop({ type: String, default: '' }) value!: string
  @Prop({ type: Boolean, default: false }) disableValue?: boolean

  emit (value: string): void {
    this.$emit('input', value)
  }

  get filteredRegions (): {
    name: string;
    iso2: string;
    dialCode: string;
    priority: number | number[];
    areaCodes: string[] | null;
  }[] {
    return REGIONS.filter(item => this.includes.length === 0 || this.includes.includes(item.iso2.toLowerCase()))
  }

  get items (): {
    text: string;
    iso2: string;
    value: string;
  }[] {
    return this.filteredRegions.map(item => {
      return {
        text: `${item.name} (+${item.dialCode})`,
        iso2: item.iso2,
        value: item.iso2.toLowerCase()
      }
    })
  }
}
