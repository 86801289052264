import { render, staticRenderFns } from "./LayoutMain.vue?vue&type=template&id=fed15e70&scoped=true&"
import script from "./LayoutMain.vue?vue&type=script&lang=ts&"
export * from "./LayoutMain.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed15e70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VContainer,VMain})
