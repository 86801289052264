import firebase, { db } from '@/plugins/firebase'
import VehicleInfo from '../../functions/src/types/vehicle/info'
import { VehicleSecond } from '../../functions/src/types/vehicle/second'

export class Device {
  constructor (
    readonly deviceId: string,
    readonly info: VehicleInfo,
    readonly lastSecond: VehicleSecond,
    readonly company?: firebase.firestore.DocumentReference | null,
    readonly group?: firebase.firestore.DocumentReference | null,
    readonly createdAt?: Date,
    readonly updatedAt?: Date
  ) {

  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Device> = {
  toFirestore (model: Partial<Device>, setOptions?: firebase.firestore.SetOptions) {
    if (setOptions?.merge) {
      return Object.assign(model, { updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
    }

    return model
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Device(
      data.deviceId,
      data.info,
      data.lastSecond,
      data.company,
      data.group,
      data.createdAt ? data.createdAt.toDate() : null,
      data.updatedAt ? data.updatedAt.toDate() : null
    )
  }
}
export default db.collection('devices').withConverter(converter)
