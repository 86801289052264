import marked from 'marked'
// https://marked.js.org/using_advanced#options
marked.setOptions({
  // renderer: new marked.Renderer(),
  // highlight: function(code, lang) {
  //   const hljs = require('highlight.js');
  //   const language = hljs.getLanguage(lang) ? lang : 'plaintext';
  //   return hljs.highlight(code, { language }).value;
  // },
  // pedantic: false,
  gfm: true,
  breaks: true,
  // sanitize: false,
  smartLists: true,
  smartypants: true
  // xhtml: false
})

export default marked
