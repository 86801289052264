








import { Component, Vue } from 'vue-property-decorator'
import marked from '@/plugins/marked'

@Component<TermsContent>({
  created () {
    this.init()
  }
})
export default class TermsContent extends Vue {
  text = ''

  async init (): Promise<void> {
    const r = await fetch('/terms.md')
    const text = await r.text()
    this.text = marked(text)
  }
}
