















import { Component, Vue } from 'vue-property-decorator'
import LayoutMainAppBar from '@/components/layout/LayoutMainAppBar.vue'
import LayoutMainFooter from '@/components/layout/LayoutMainFooter.vue'

@Component({ components: { LayoutMainAppBar, LayoutMainFooter } })
export default class LayoutMain extends Vue {
  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
