































import { Component, Vue } from 'vue-property-decorator'
import TermsDialog from '@/components/info/TermsDialog.vue'
import PrivacyDialog from '@/components/info/PrivacyDialog.vue'

  @Component({ components: { TermsDialog, PrivacyDialog } })
export default class LayoutMainFooter extends Vue {

}
