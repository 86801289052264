import firebase, { db } from '@/plugins/firebase'

export class Company {
  constructor (
    readonly name: string,
    readonly description: string,
    readonly createdAt?: Date,
    readonly updatedAt?: Date
  ) {

  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Company> = {
  toFirestore (model: Partial<Company>, setOptions?: firebase.firestore.SetOptions) {
    if (setOptions?.merge) {
      return Object.assign(model, { updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
    }
    return {
      name: model.name,
      description: model.description,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Company(
      data.name,
      data.description,
      data.createdAt ? data.createdAt.toDate() : new Date(),
      data.updatedAt ? data.updatedAt.toDate() : new Date()
    )
  }
}
export default db.collection('companies').withConverter(converter)
