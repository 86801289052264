




































































































































import { Component, Vue } from 'vue-property-decorator'
import { version } from '../../../package.json'
import AuthStore from '@/store/AuthStore'
import AuthMenu from '@/components/common/AuthMenu.vue'

interface SubMenuItem {
  icon: string;
  text: string;
  to: string;
}

interface MenuItem {
  icon: string;
  text: string;
  active: boolean;
  actions: string[];
  items: SubMenuItem[];
}

@Component({ components: { AuthMenu } })
export default class LayoutMainAppBar extends Vue {
  drawer = false
  version = version

  menuItems = [

    {
      icon: 'mdi-account',
      text: '나의 데이터',
      active: true,
      actions: ['read'],
      items: [
        { icon: 'mdi-chart-donut', text: '요약', to: '/dashboard' },
        { icon: 'mdi-map-marker-outline', text: '현재상태', to: '/current' },
        { icon: 'mdi-history', text: '주행기록', to: '/day' },
        { icon: 'mdi-information-outline', text: '단말기정보', to: '/device' }
      ]
    },
    {
      icon: 'mdi-map',
      text: '기록',
      active: false,
      actions: ['admin', 'company-admin', 'group-admin'],
      items: [
        { icon: 'mdi-chart-donut', text: '요약', to: '/monitor' },
        { icon: 'mdi-map-marker-outline', text: '최근위치', to: '/monitor/map' },
        { icon: 'mdi-history', text: '주행기록', to: '/monitor/day' },
        { icon: 'mdi-car', text: '차량현황', to: '/monitor/device' }
      ]
    },
    {
      icon: 'mdi-sitemap',
      text: '관리',
      active: false,
      actions: ['admin', 'company-admin', 'group-admin'],
      items: [
        { icon: 'mdi-memory', text: '장치관리', to: '/manage/device' },
        { icon: 'mdi-office-building', text: '회사관리', to: '/manage/company' },
        { icon: 'mdi-account-multiple-outline', text: '사용자관리', to: '/manage/user' },
        { icon: 'mdi-shape-outline', text: '펌웨어관리', to: '/manage/firmware' },
        { icon: 'mdi-format-list-bulleted-type', text: '이력관리', to: '/manage/log' }
      ]
    },
    {
      icon: 'mdi-information-outline',
      text: '정보',
      active: false,
      actions: ['read'],
      items: [
        { icon: 'mdi-application', text: '앱다운로드', to: '/info/download' },
        { icon: 'mdi-help-circle-outline', text: '도움말', to: '/info/help' },
        { icon: 'mdi-information-outline', text: '시스템정보', to: '/system' }
      ]
    },
    {
      icon: 'mdi-code-braces',
      text: '개발자',
      active: false,
      actions: ['admin'],
      items: [
        { icon: 'mdi-database-cog', text: 'data', to: '/dev' }
      ]
    }
    // {
    //   icon: 'mdi-nail',
    //   text: 'test',
    //   active: false,
    //   items: [
    //     { icon: 'mdi-chart-donut', text: '요약', to: '/' },
    //     { icon: 'mdi-office-building', text: '회사관리', to: '/company' },
    //     { icon: 'mdi-eye-outline', text: '감시목록', to: '/view' },
    //     { icon: 'mdi-shape-outline', text: '펌웨어관리', to: '/firmware' },
    //     { icon: 'mdi-history', text: '이력관리', to: '/log' },
    //     { icon: 'mdi-view-dashboard-outline', text: '모델관리', to: '/model' },
    //     { icon: 'mdi-account-multiple-outline', text: '사용자관리', to: '/user' },
    //     { icon: 'mdi-alpha-v-circle-outline', text: '업데이트', to: '/version' },
    //     { icon: 'mdi-help-circle-outline', text: '도움말', to: '/help' },
    //     { icon: 'mdi-server', text: '시스템관리', to: '/system' },
    //     { icon: 'mdi-information-outline', text: '시스템정보', to: '/info' }

    //   ]
    // }
  ]

  get siteConfig (): { title: string, description: string } {
    return {
      title: process.env.VUE_APP_SITE_TITLE || '',
      description: process.env.VUE_APP_SITE_DESCRIPTION || ''
    }
  }

  get actions (): string[] {
    if (!AuthStore.user) return []
    return AuthStore.user.actions
  }

  get items (): MenuItem[] {
    return this.menuItems.filter(item => {
      return item.actions.some(action => {
        return this.actions.includes(action)
      })
    })
  }
}
