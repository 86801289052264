import firebase, { db } from '@/plugins/firebase'
import VehicleInfo from '../../functions/src/types/vehicle/info'
import { VehicleSecond } from '../../functions/src/types/vehicle/second'
import { DriveScore } from '../../functions/src/types/vehicle/drive-score'

export class Day {
  constructor (
    readonly device: firebase.firestore.DocumentReference,
    readonly info: VehicleInfo,
    readonly firstSecond: VehicleSecond,
    readonly lastSecond: VehicleSecond,
    readonly tripCount: number,
    readonly distance: number,
    readonly driveTime: number,
    readonly driveScore: DriveScore,
    readonly company: firebase.firestore.DocumentReference | null,
    readonly group: firebase.firestore.DocumentReference | null,
    readonly firstAddress?: string,
    readonly lastAddress?: string,
    readonly createdAt?: Date,
    readonly updatedAt?: Date
  ) {

  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Day> = {
  toFirestore (model: Partial<Day>) {
    return model
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Day(
      data.device,
      data.info,
      // Object.assign(data.firstSecond, { time: new Date(data.firstSecond.time) }),
      // Object.assign(data.lastSecond, { time: new Date(data.lastSecond.time) }),
      data.firstSecond,
      data.lastSecond,
      data.tripCount,
      data.distance,
      data.driveTime,
      data.driveScore,
      data.company,
      data.group,
      data.firstAddress || '',
      data.lastAddress || '',
      data.createdAt ? data.createdAt.toDate() : null,
      data.updatedAt ? data.updatedAt.toDate() : null
    )
  }
}
export default db.collection('days').withConverter(converter)
