import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/src/locale/en'
import ko from 'vuetify/src/locale/ko'

Vue.use(Vuetify)

const options = {
  lang: {
    locales: { en, ko },
    current: 'ko'
  },
  theme: {
    themes: {
      light: {
        // primary: '#1976D2',
        // secondary: '#424242',
        // info: '#2196F3',
        // success: '#4CAF50',
        primary: '#344955',
        secondary: '#4A6572',
        accent: '#232F34',
        info: '#82B1FF'
        // success: '#F9AA33',
        // warning: '#FB8C00',
        // error: '#FF5252'
      },
      dark: {
        primary: '#1EB980',
        secondary: '#045D56',
        // accent: '#232F34',
        info: '#82B1FF',
        tertiary: '#FF6859',
        quaternary: '#FFCF44',
        quinary: '#B15DFF',
        senary: '#72DEFF'
      }
    }

  }
  // preset
}

export default new Vuetify(options)
