







import { Vue, Component } from 'vue-property-decorator'
import LayoutLoading from '@/components/layout/LayoutLoading.vue'
import LayoutMain from '@/components/layout/LayoutMain.vue'
import LayoutAuth from '@/components/layout/LayoutAuth.vue'
import LayoutCenter from '@/components/layout/LayoutCenter.vue'
import AuthStore from '@/store/AuthStore'

@Component<App>({
  components: { LayoutLoading, LayoutMain, LayoutAuth, LayoutCenter }

})
export default class App extends Vue {
  get loading (): boolean {
    return AuthStore.loading
  }

  get existsDisplayName (): boolean {
    return AuthStore.existsDisplayName
  }

  get isCenterPage (): boolean {
    return this.$route.path === '/help' ||
     this.$route.path === '/terms' ||
     this.$route.path === '/privacy' ||
     this.$route.path === '/download'
  }
}
