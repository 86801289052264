






import { Component, Vue, Prop } from 'vue-property-decorator'
import PhoneNumber from 'awesome-phonenumber'
@Component
export default class DisplayPhoneNumber extends Vue {
  @Prop({ type: String }) text!: string

  get phoneNumber (): string {
    return new PhoneNumber(this.text, 'KO').getNumber('national')
  }
}
