import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { logAdd } from '@/models/log'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/components/owner/OwnerDashboardLoading.vue')
  },
  {
    path: '/dashboard',
    component: () => import('@/components/owner/OwnerDashboardIndex.vue')
  },
  {
    path: '/dashboard/:id',
    component: () => import('@/components/monitor/MonitorDashboardIndex.vue')
  },
  {
    path: '/current',
    component: () => import('@/components/owner/OwnerCurrentIndex.vue')
  },
  {
    path: '/day',
    component: () => import('@/components/owner/OwnerDayIndex.vue')
  },
  {
    path: '/device',
    component: () => import('@/components/owner/OwnerDeviceIndex.vue')
  },
  {
    path: '/monitor',
    component: () => import('@/components/monitor/MonitorIndex.vue')
  },
  {
    path: '/monitor/map',
    component: () => import('@/components/monitor/MonitorMapIndex.vue')
  },
  {
    path: '/monitor/device',
    component: () => import('@/components/monitor/MonitorDeviceIndex.vue')
  },
  {
    path: '/monitor/day',
    component: () => import('@/components/monitor/MonitorDayIndex.vue')
  },
  {
    path: '/manage/device',
    component: () => import('@/components/manage/DeviceIndex.vue')
  },
  {
    path: '/manage/company',
    component: () => import('@/components/manage/CompanyIndex.vue')
  },
  {
    path: '/manage/user',
    component: () => import('@/components/manage/UserIndex.vue')
  },
  {
    path: '/manage/firmware',
    component: () => import('@/components/manage/FirmwareIndex.vue')
  },
  {
    path: '/manage/log',
    component: () => import('@/components/manage/LogIndex.vue')
  },
  {
    path: '/help',
    component: () => import('@/components/info/HelpIndex.vue')
  },
  {
    path: '/privacy',
    component: () => import('@/components/info/PrivacyIndex.vue')
  },
  {
    path: '/terms',
    component: () => import('@/components/info/TermsIndex.vue')
  },
  {
    path: '/download',
    component: () => import('@/components/info/DownloadIndex.vue')
  },
  {
    path: '/info/help',
    component: () => import('@/components/info/HelpIndex.vue')
  },
  {
    path: '/info/download',
    component: () => import('@/components/info/DownloadIndex.vue')
  },
  {
    path: '/dev',
    component: () => import('@/components/admin/index.vue')
  },
  {
    path: '*',
    name: 'e404',
    component: () => import('@/components/common/PageNotFound.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  logAdd('route', to.path, 'visited', 'admin')
  next()
})

export default router
