

















































import { Component, Vue } from 'vue-property-decorator'
import AuthSignIn from '@/components/auth/sign-in.vue'
import AuthStore from '@/store/AuthStore'
import AuthDisplayName from '@/components/auth/display-name.vue'
import AuthIntro from '@/components/auth/intro.vue'
// import firebase, { auth } from '@/plugins/firebase'
@Component({ components: { AuthSignIn, AuthDisplayName, AuthIntro } })
export default class LayoutAuth extends Vue {
  loading = false
  get isAuthenticated (): boolean {
    return AuthStore.isAuthenticated
  }
}
