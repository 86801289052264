
































import { Component, Vue, Ref } from 'vue-property-decorator'
import AuthStore from '@/store/AuthStore'

@Component
export default class AuthDisplayName extends Vue {
  @Ref('form') form!: { validate(): boolean }
  displayName = ''
  submit (): void {
    if (!this.form.validate()) return
    AuthStore.updateDisplayName(this.displayName)
    this.$toast.success('회원 가입이 완료되었습니다')
  }
}
