












































































































































import { Component, Vue } from 'vue-property-decorator'

  @Component
export default class AuthIntro extends Vue {
  items= [
    {
      background: 'https://images.unsplash.com/photo-1465101162946-4377e57745c3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1357&q=80',
      src: '/img/help/app-4-device-select.png',
      caption: 'DTG 검색',
      title: '빠른 연결',
      contents: [
        '별도의 페어링 없이 블루투스만 켜져 있으면',
        '주변 DTG를 바로 찾을 수 있습니다'
      ]
    },
    {
      background: 'https://images.unsplash.com/photo-1465101162946-4377e57745c3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1357&q=80',
      src: '/img/help/app-5-device-pin.png',
      caption: 'DTG 연결',
      title: '빠른 연결',
      contents: [
        '블루투스로',
        'DTG에 바로 연결됩니다'
      ]
    },
    {
      background: 'https://images.unsplash.com/photo-1518066000714-58c45f1a2c0a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      src: '/img/help/app-7-map.png',
      caption: '메인화면: 아이폰',
      title: '멀티 플랫폼',
      contents: [
        '아이폰, 안드로이드 모두 지원합니다',
        '애플 앱스토어에서 다운로드 가능합니다'
      ]

    },
    {
      background: 'https://images.unsplash.com/photo-1518066000714-58c45f1a2c0a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      src: '/img/help/app-8-map-android.png',
      caption: '메인화면: 안드로이드',
      title: '멀티 플랫폼',
      contents: [
        '아이폰, 안드로이드 모두 지원합니다',
        '구글 플레이스토어에서 다운로드 가능합니다'
      ]
    },
    {
      background: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1352&q=80',
      src: '/img/help/app-11-etas-login.png',
      caption: '이타스 로그인',
      title: '이타스(E-TAS)',
      contents: [
        '이타스 계정이 있다면',
        '로그인 후 바로 전송 가능합니다'
      ]
    },
    {
      background: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1352&q=80',
      src: '/img/help/app-12-etas-menu.png',
      caption: '이타스 메뉴',
      title: '이타스(E-TAS)',
      contents: [
        '이타스 전송 이력 및 통계를',
        '바로 확인할 수 있습니다'
      ]
    },
    {
      background: 'https://images.unsplash.com/photo-1498116069452-debf99cb30f0?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      src: '/img/help/app-13-etas-log.png',
      caption: '이타스 전송',
      title: '무손실 데이터',
      contents: [
        'DTG의 최근 데이터를 받는 것이 아닌 동기화 방식이기 때문에',
        '데이터 손실이 없습니다'
      ]
    },
    {
      background: 'https://images.unsplash.com/photo-1498116069452-debf99cb30f0?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      src: '/img/help/app-9-trip.png',
      caption: '운행 이력',
      title: '무손실 데이터',
      contents: [
        '한눈에 주행이력을 확인할 수 있습니다'
      ]
    }
  ]
}
