// import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'
// import 'firebase/functions'
import 'firebase/firebase-messaging'
import firebaseConfig from '../../firebaseConfig'

firebase.initializeApp(firebaseConfig)
firebase.auth().useDeviceLanguage()

export const auth = firebase.auth()
export const db = firebase.firestore()
export const rdb = firebase.database()
export const storage = firebase.storage()
// export const functions = firebase.functions()
// export const messaging = firebase.messaging()

if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_FIREBASE_DATA === 'emulator') {
  // auth.useEmulator('http://localhost:9099/', { disableWarnings: true })
  auth.useEmulator('http://localhost:9099/')
  db.useEmulator('localhost', 8888)
  rdb.useEmulator('localhost', 9000)
  storage.useEmulator('localhost', 9199)
  // functions.useEmulator('http://localhost', 5001)
}

export default firebase
