import firebase, { db } from '../plugins/firebase'

export class User {
  constructor (
    readonly phoneNumber: string,
    readonly displayName: string,
    readonly photoURL: string,
    readonly actions: string[],
    readonly companies: firebase.firestore.DocumentReference[],
    readonly groups: firebase.firestore.DocumentReference[],
    readonly fcmTokens: string[],
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly visitedAt: Date,
    readonly visitCount: number
  ) {}
}

export const converter: firebase.firestore.FirestoreDataConverter<User> = {
  toFirestore (model: Partial<User>, setOptions?: firebase.firestore.SetOptions): firebase.firestore.DocumentData {
    if (setOptions?.merge) {
      return Object.assign(model, { updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
    }
    return model
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot): User {
    const data = snapshot.data()
    let photoURL = data.photoURL
    if (photoURL.includes('gravatar')) photoURL += '?d=retro'
    return new User(
      data.phoneNumber,
      data.displayName,
      photoURL,
      data.actions,
      data.companies || [],
      data.groups || [],
      data.fcmTokens,
      data.createdAt ? data.createdAt.toDate() : null,
      data.updatedAt ? data.updatedAt.toDate() : null,
      data.visitedAt ? data.visitedAt.toDate() : null,
      data.visitCount
    )
  }
}

export default db.collection('users').withConverter(converter)
