import firebase, { db } from '@/plugins/firebase'
import userCollection, { User } from '@/models/user'
import AuthStore from '@/store/AuthStore'

export class Log {
  constructor (
    readonly type: string,
    readonly title: string,
    readonly body: string,
    readonly target: string,
    readonly user?: firebase.firestore.DocumentReference<User> | null,
    readonly notice?: boolean | null,
    readonly link?: string | null,
    readonly createdAt?: Date
  ) {
  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Log> = {
  toFirestore (model: Partial<Log>) {
    return {
      type: model.type,
      title: model.title,
      body: model.body,
      target: model.target,
      user: model.user || null,
      notice: model.notice || false,
      link: model.link || '',
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Log(
      data.type,
      data.title,
      data.body,
      data.target,
      data.user || null,
      data.notice || false,
      data.link || '',
      (data.createdAt as firebase.firestore.Timestamp)?.toDate()
    )
  }
}

const logCollection = db.collection('logs').withConverter(converter)

export const logAdd = (type: string, title: string, body: string, target: string, notice?: boolean, link?: string): void => {
  if (!AuthStore.firebaseUser) return
  const userRef = userCollection.doc(AuthStore.firebaseUser.uid)
  logCollection.add(new Log(type, title, body, target, userRef, notice, link))
}

export default logCollection
