











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import PhoneNumber from 'awesome-phonenumber'
// import userCollection from '@/models/user'
import firebase from '@/plugins/firebase'

@Component<RenderlessSignIn>({
  name: 'renderless-sign-in',
  mounted () {
    this.$nextTick(() => {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptchaBtnId, {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.onSignInWithPhoneNumberSubmit()
        }
      })
    })
  },
  beforeDestroy () {
    if (this.recaptchaVerifier) this.recaptchaVerifier.clear()
  }
})
export default class RenderlessSignIn extends Vue {
  loading = false
  uid = ''
  confirmationResult: firebase.auth.ConfirmationResult | null = null
  googleProvider = new firebase.auth.GoogleAuthProvider()
  recaptchaVerifier: null | firebase.auth.RecaptchaVerifier = null

  @Prop({ type: String, required: true }) phoneNumber!: string
  @Prop({ type: String, required: true }) regionCode!: string
  @Prop({ type: String, required: true }) recaptchaBtnId!: string

  getPhoneNumber (): string {
    const phoneNumber = new PhoneNumber(this.phoneNumber, this.regionCode)

    if (!phoneNumber.isValid()) throw new Error('올바른 휴대전화번호를 입력하세요.')

    return phoneNumber.getNumber()
  }

  async signInWithPhoneNumber (): Promise<void> {
    this.loading = true

    if (!this.recaptchaVerifier) {
      this.$emit('error', '리캡챠 에러')
      return
    }

    try {
      const phoneNumber = this.getPhoneNumber()
      this.confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier)
    } catch (err) {
      this.loading = false
      this.$emit('error', err.message)
    }
  }

  async onSignInWithPhoneNumberSubmit (): Promise<void> {
    this.loading = false
    this.$emit('update:step', 1)
  }

  async verifyPhoneNumber (verificationCode: string): Promise<void> {
    this.loading = true
    if (!this.confirmationResult) return

    try {
      const res = await this.confirmationResult.confirm(verificationCode)
      // console.log(res)
      // const uid = res.user?.uid

      // if (!uid) throw new Error('Invalid user uid')

      // this.uid = uid

      // const userRef = userCollection.doc(this.uid)

      // const userDoc = await userRef.get()

      // if (!userDoc.exists) {
      //   await new Promise((resolve, reject) => {
      //     const unsubscribe = userCollection.doc(this.uid).onSnapshot((snapshot) => {
      //       if (snapshot.exists) {
      //         unsubscribe()
      //         return resolve(null)
      //       }
      //     }, (err) => {
      //       unsubscribe()
      //       reject(err)
      //     })
      //   })
      // }

      this.$emit('authorized')
    } catch (err) {
      console.error(err)
      this.$emit('error', err.message)
      this.loading = false
    }
  }

  async resendVerificationCode (): Promise<void> {
    this.loading = true
    try {
      if (!this.confirmationResult) throw new Error('Invalid confimation result')

      await this.signInWithPhoneNumber()
    } catch (err) {
      this.$emit('error', err.message)
    }
    this.loading = false
  }
}
