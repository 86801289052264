














































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import RenderlessSignIn from '@/components/auth/renderless-sign-in.vue'
import CountryCodeSelect from '@/components/auth/country-code-select.vue'
import platForm from 'platform-detect'

@Component<AuthSignIn>({
  name: 'sign-in',
  components: {
    RenderlessSignIn,
    CountryCodeSelect
  }
})
export default class AuthSignIn extends Vue {
  step = 0
  regionCode = 'kr'
  phoneNumber = ''
  verificationCode = ''
  submitBtnId = 'phone-number-submit-btn'
  isLogin = true
  downloadLink = ''

  // get placeholder () {
  //   return this.isLogin ? '로그인' : '회원가입'
  // }

  onError (message: string): void {
    alert(message)
  }

  onAuthorized (): void {
    // this.$router.push('/')
    console.log('onAuthorized')
  }

  get getPlatform (): boolean | null {
    if (platForm.ios) {
      this.downloadLink = 'https://apps.apple.com/kr/app/mobile-dtg/id1541985964'
      return true
    }
    if (platForm.android) {
      this.downloadLink = 'https://play.google.com/store/apps/details?id=com.loopsdteam.dtg_fire'
      return true
    }
    return null
  }
}
