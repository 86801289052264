













































import { Component, Vue } from 'vue-property-decorator'
import PrivacyContent from '@/components/info/PrivacyContent.vue'

@Component<PrivacyDialog>({
  components: { PrivacyContent }
})
export default class PrivacyDialog extends Vue {
  dialog = false

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
