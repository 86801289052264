













































import { Component, Vue } from 'vue-property-decorator'
import TermsContent from '@/components/info/TermsContent.vue'

@Component<TermsDialog>({
  components: { TermsContent }
})
export default class TermsDialog extends Vue {
  dialog = false

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
