





















































import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/AuthStore'
import { User } from '@/models/user'
import DisplayPhoneNumber from '@/components/common/DisplayPhoneNumber.vue'

@Component({ components: { DisplayPhoneNumber } })
export default class AuthMenu extends Vue {
  get item (): User | null {
    return AuthStore.user
  }

  signOut (): void {
    AuthStore.signOut()
  }
}
